import {Component, OnInit} from '@angular/core';
import {PortService} from "../service/port.service";
import {Observable, ReplaySubject} from "rxjs";
import {PortDTO} from "../service/dto";
import {ToastrService} from "ngx-toastr";
import {NgEventBus} from "ng-event-bus";
import {TitleEvent} from "../layout/header/header.component";
import {ActivatedRoute, Router} from "@angular/router";
import {ConfigService} from "../service/config.service";

@Component({
  selector: 'app-port-list',
  templateUrl: './port-list.component.html',
  styleUrl: './port-list.component.scss'
})
export class PortListComponent implements OnInit {
  data$ = new ReplaySubject();
  data: Observable<PortDTO[]>
  constructor(private portService: PortService,
              private eventBus: NgEventBus,
              private route: ActivatedRoute,
              private configService: ConfigService,
              private router: Router,
              private toastService: ToastrService) {
    this.eventBus.cast('page:title', {pageTitle: 'Porti disponibili'} as TitleEvent);

    this.data = this.configService.availablePorts
  }

  ngOnInit(): void {
    this.data$.next(null)
  }

  goToDashboard(port: PortDTO) {
    this.configService.selectedPort = port;
    this.router.navigate(['/','dashboard'])
  }
}

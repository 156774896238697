import {Injectable} from "@angular/core";
import {NgbDateAdapter, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import moment from "moment"
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class NgbDateCustomParserAdapter extends NgbDateAdapter<string> {
  constructor(private translateService: TranslateService) {
    super();

  }

  fromModel(value: string | null): NgbDateStruct | null {

    if (!value) {
      return null;
    }

    const parts = moment(value);
    return {year: +parts.year(), month: +parts.month() + 1, day: +parts.date()} as NgbDateStruct;
  }

  toModel(date: NgbDateStruct | null): string | null {

    if (!date)
      return null
    return `${date.year}-${('0' + (date.month)).slice(-2)}-${('0' + date.day).slice(-2)}`
  }
}
